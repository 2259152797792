<template>
  <v-card>
    <v-toolbar
      density="compact"
      color="primary"
      class="pl-5 text-uppercase font-weight-medium"
    >
      <v-icon size="small" class="mr-2">mdi-hospital-building</v-icon>
      Resurssitaulu
    </v-toolbar>

    <v-card-title class="text-primary text-h5 text-uppercase font-weight-medium"
      >Metsolan keskussairaala</v-card-title
    >
    <v-card-text v-for="resourceArea in resources" :key="resourceArea.id">
      <v-row
        ><v-col class="area-title text-h6 primary--text">{{
          resourceArea.name
        }}</v-col></v-row
      >
      <v-row
        v-for="resourceGroup in resourceArea.resourceGroups"
        :key="resourceGroup.id"
        style="margin-left: 2px;"
      >
        <v-col cols="2" class="group-title">{{ resourceGroup.name }}</v-col>
        <v-col cols="10">
          <v-row>
            <ResourceTableItem
              v-for="resource in resourceGroup.resources"
              :key="resource.id"
              :resource="resource"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="sass">
.area-title
  font-weight: 700
.group-title
  font-weight: 600
  text-align: end
  padding-right: 20px
</style>

<script>
import ResourceTableItem from "./ResourceTableItem.vue";

// TODO: Chechk functionality and styling with other cases when we get those

export default {
  props: {},
  components: {
    ResourceTableItem
  },
  data: () => ({}),
  computed: {
    resources: {
      get() {
        let me = this;
        return me.$store.getters["resources/resources"];
      }
    }
  }
};
</script>
