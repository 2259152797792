<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <UnitListWidget :isLoading="isLoading" class="unit-list" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.unit-list
  margin-top: 10px
  margin-bottom: 10px
</style>
<script>
// @ is an alias to /src
import UnitListWidget from "@/components/UnitListWidget.vue";

export default {
  name: "UnitsComponent",
  components: {
    UnitListWidget
  },
  data: () => ({
    isLoading: false
  }),
  async mounted() {
    let me = this;
    try {
      me.isLoading = true;
    } catch (err) {
      me.$debug(err);
    }
    me.isLoading = false;
  }
};
</script>
